<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-sm">
        <q-select
          outlined
          stack-label
          bottom-slots
          v-if="asal == 'musyrif'"
          :options="opt_musyrif"
          v-model="tipe"
          label="Tipe File"
        >
          <template v-slot:hint>
            <a class="text-negative"
              >*Pastikan anda memilih Tipe File dengan Benar</a
            >
          </template>
        </q-select>
        <q-select
          outlined
          stack-label
          bottom-slots
          v-if="asal == 'kelas'"
          :options="$LIST_JENJANG"
          v-model="jenjang"
          label="Jenjang"
        >
          <template v-slot:hint>
            <a class="text-negative"
              >*Pastikan anda memilih Jenjang dengan Benar</a
            >
          </template>
        </q-select>
        <q-file
          outlined
          bottom-slots
          label="Klik Untuk Pilih File"
          stack-label
          @input="importFile"
          v-model="file"
        >
          <template v-slot:prepend>
            <q-icon name="cloud_upload" />
          </template>
        </q-file>
      </q-card-section>
      <q-card-actions aligh="right">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn
          unelevated
          :disable="file == null || (asal == 'musyrif' && tipe == null)"
          color="primary"
          @click="onOKClick"
          label="baca file"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["asal"],
  data() {
    return {
      opt_musyrif: [
        {
          label: "Musyrif",
          tipe_id: "id_musyrif",
          tipe_is: "is_musyrif",
          rows: "musyrif",
          tab: "musyrif",
        },
        {
          label: "Murabbi Tahfidz",
          tipe_id: "id_murabbi_tahfidz",
          tipe_is: "is_murabbi_tahfidz",
          rows: "murabbi_tahfidz",
          tab: "tahfidz",
        },
        {
          label: "Murabbi Mentor",
          tipe_id: "id_murabbi_mentor",
          tipe_is: "is_murabbi_mentor",
          rows: "murabbi_tahfidz",
          tab: "mentor",
        },
      ],
      jenjang: this.$LIST_JENJANG[0],
      tipe: null,
      file: null,
    };
  },
  mounted() {},
  methods: {
    importFile(file) {
      this.file = file;
    },

    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      let data = {
        file: this.file,
        tipe: this.tipe,
        jenjang: this.jenjang,
      };
      this.$emit("ok", data);
      this.hide();
    },

    onCancelClick() {
      this.hide();
    },
  },
};
</script>
