<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="min-height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px" class="q-mt-xl">
      <q-card-section class="q-gutter-sm">
        <q-select
          :options="$LIST_JENJANG"
          v-model="selJenjang"
          outlined
          @input="refreshData"
          label="Jenjang"
          style="width: 310px;"
        ></q-select>
      </q-card-section>
      <q-card-section class="row justify-between q-pt-none">
        <div class="row q-gutter-sm">
          <q-select
            :options="listTingkat"
            v-model="selTingkat"
            @input="
              (selKelas = ''), (listKanan = []), getSiswaList(), getKelasList()
            "
            outlined
            label="Tingkat"
            style="width: 150px;"
          ></q-select>
          <q-input outlined label="Cari Siswa/NISN" v-model="searchTerm">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>

        <q-select
          class="self-end"
          use-input
          @filter="filterFn"
          :options="filteredListKelas"
          v-model="selKelas"
          @input="getSiswaKelasList()"
          outlined
          label="Kelas"
          style="width: 250px;"
        ></q-select>
      </q-card-section>
      <q-card-section class="row justify-between items-center">
        <!-- kiri  -->
        <q-markup-table
          flat
          bordered
          dense
          separator="horizontal"
          style="width:45%"
          class="stickyTable bg-grey-4"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th style="width:20px;">
                <q-checkbox
                  @input="checkingAll('kiri')"
                  v-model="checkAllKiri"
                ></q-checkbox>
              </th>
              <th>no</th>
              <th>nisn</th>
              <th>nama</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-for="(val, i) in filSiswa" :key="i">
              <td>
                <q-checkbox v-model="val.check"></q-checkbox>
              </td>
              <td>{{ i + 1 }}</td>
              <td>{{ val.nisn }}</td>
              <td>{{ val.nama }}</td>
            </tr>
          </tbody>
        </q-markup-table>
        <div class="column q-gutter-sm">
          <q-btn
            outline
            color="black"
            :disable="selKelas == ''"
            @click="(isChanged = true), toGlobal(), print()"
            icon="keyboard_arrow_left"
          ></q-btn>
          <q-btn
            outline
            color="black"
            :disable="selKelas == ''"
            @click="(isChanged = true), toKelas(), print()"
            icon="keyboard_arrow_right"
          ></q-btn>
        </div>
        <!-- kanan  -->
        <q-markup-table
          flat
          bordered
          dense
          separator="horizontal"
          style="width:45%"
          class="stickyTable bg-grey-4"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th style="width:20px;">
                <q-checkbox
                  :disable="selKelas == ''"
                  @input="checkingAll('kanan')"
                  v-model="checkAllKanan"
                ></q-checkbox>
              </th>
              <th>no</th>
              <th>nisn</th>
              <th>nama</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-show="!selKelas">
              <td colspan="4" class="text-caption text-grey-7 text-center">
                Silahkan pilih kelas terlebih dahulu
              </td>
            </tr>
            <tr v-show="listKanan.length == 0 && selKelas">
              <td colspan="4" class="text-caption text-grey-7 text-center">
                Tidak Ada Siswa Dalam Kelas Ini
              </td>
            </tr>
            <tr v-for="(val, i) in listKanan" :key="i">
              <td>
                <q-checkbox v-model="val.check"></q-checkbox>
              </td>
              <td>{{ i + 1 }}</td>
              <td>{{ val.nisn }}</td>
              <td>{{ val.nama }}</td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>

      <q-card-actions align="between">
        <q-btn
          outline
          color="primary"
          label="Import Excel"
          @click="showDialogImport"
        ></q-btn>
        <q-btn
          v-show="isChanged"
          color="primary"
          label="simpan"
          @click="show = true"
        ></q-btn>
      </q-card-actions>
    </q-card>
    <q-dialog v-model="show" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <p>Apa anda yakin ingin menyimpan perubahan?</p>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            label="Ya"
            color="primary"
            @click="simpan"
            v-close-popup
          />
          <q-btn flat label="Tidak" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DialogImportKelasKamar from "@/components/DialogImportKelasKamar";
import General from "@/mixins/General";
export default {
  mixins: [General],
  data() {
    return {
      file: null,
      insert: true,
      kelas_all: [],

      searchTerm: "",
      show: false,
      isChanged: false,
      checkAllKanan: false,
      checkAllKiri: false,
      selJenjang: this.$LIST_JENJANG[0],
      listTingkat: [],
      selTingkat: "",
      listKelas: [],
      filteredListKelas: [],
      listKiri: [
        {
          id: "",
          nama: "",
          nisn: "",
          check: "",
        },
      ],
      listKanan: [],
      selKelas: "",
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.listKiri;
      else {
        return this.listKiri.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    },
  },
  async mounted() {
    this.refreshData();
  },
  methods: {
    showDialogImport() {
      this.$q
        .dialog({
          component: DialogImportKelasKamar,
          parent: this,
          asal: "kelas",
        })
        .onOk(async (resp) => {
          let file = await this.$hlp.ReadXLSX(resp.file);

          this.fileReady(file, resp.jenjang);
        });
    },

    async fileReady(file, jenjang) {
      this.$q.loading.show({ message: "please wait" });
      let listKelas = [];
      let rows = file.split("\n");
      for (let item of rows) {
        let temp = item.split(";");
        if (temp[0] != "") {
          if (parseInt(temp[0]) > 0) {
            let carier = {};
            carier.id = temp[0];
            carier.kelas = temp[6];
            carier.id_rombel = -1;
            listKelas.push(carier);
          }
        }
      }
      //memasang id kelas

      let resp = await this.$http.get(`/akademik/kelas/getallkelas/${jenjang}`);
      let kelas_jenjang = resp.data;

      // k list kelas
      // s list siswa dengan kelas dari import excell
      for (let kelas of kelas_jenjang) {
        for (let siswa of listKelas) {
          if (siswa.kelas == kelas.label) {
            siswa.id_rombel = kelas.value;
            siswa.tingkat = kelas.tingkat;
            siswa.peminatan = "";
            if (jenjang == "MA") {
              siswa.peminatan = kelas.peminatan;
            }
          }
        }
      }
      await this.$http.put(`/akademik/rombel/inputexcel`, listKelas);
      this.$q.loading.hide();
      this.MNotifyPositive("Berhasil import data excel");
      this.refreshData();
      this.selKelas = null;
      this.listKanan = [];
    },
    async refreshData() {
      await this.getTingkatList();
      await this.getKelasList();
      await this.getSiswaList();
    },
    filterFn(val, update) {
      if (val === "") {
        update(() => {
          this.filteredListKelas = this.listKelas;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filteredListKelas = this.listKelas.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    async getTingkatList() {
      await this.$http
        .get(
          "/akademik/kelas/gettingkat/" + this.selJenjang,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.listTingkat = resp.data;
          this.selTingkat = resp.data[this.listTingkat.length - 1];
        });
    },
    print() {
      this.$forceUpdate();
    },
    async simpan() {
      this.$q.loading.show({ message: "Menyimpan Data" });
      let idIn = [];
      let idOut = [];
      this.listKanan.forEach((number, index) => {
        idIn.push(number.id);
      });

      this.listKiri.forEach((number, index) => {
        idOut.push(number.id);
      });

      await this.$http
        .put(
          `/akademik/rombel/isi/${this.selKelas.value}/${this.selKelas.peminatan}`,
          idIn,
          this.$hlp.getHeader()
        )
        .then((resp) => {});
      await this.$http
        .put("/akademik/rombel/isi", idOut, this.$hlp.getHeader())
        .then((resp) => {});
      this.isChanged = false;
      this.$q.loading.hide();
      this.$q.notify({
        message: "proses menyimpan data berhasil",
        color: "positive",
      });
    },
    toKelas() {
      let temp = [];
      for (let item in this.listKiri) {
        if (this.listKiri[item].check) {
          temp.push(this.listKiri[item].id);
          this.listKanan.push(this.listKiri[item]);
        }
      }
      for (let item in temp) {
        this.listKiri = this.listKiri.filter(function(x) {
          return x.id != temp[item];
        });
      }
      this.simpan();
    },
    toGlobal() {
      let temp = [];
      for (let item in this.listKanan) {
        if (this.listKanan[item].check) {
          temp.push(this.listKanan[item].id);
          this.listKiri.push(this.listKanan[item]);
        }
      }
      for (let item in temp) {
        this.listKanan = this.listKanan.filter(function(x) {
          return x.id != temp[item];
        });
      }
      this.simpan();
    },
    checkingAll(pos) {
      if (pos == "kanan") {
        if (this.checkAllKanan) {
          for (let item in this.listKanan) {
            this.listKanan[item].check = true;
          }
        } else {
          for (let item in this.listKanan) {
            this.listKanan[item].check = false;
          }
        }
      } else if (pos == "kiri") {
        this.$q.loading.show({ message: "please wait" });
        if (this.checkAllKiri) {
          for (let item in this.listKiri) {
            this.listKiri[item].check = true;
          }
        } else {
          for (let item in this.listKiri) {
            this.listKiri[item].check = false;
          }
        }
        this.$q.loading.hide();
      }
    },
    async getKelasList() {
      this.$q.loading.show({ message: "Memuat Data kelas..." });
      await this.$http
        .get(
          `/akademik/rombel/getkelas/${this.selJenjang}/${this.selTingkat.value}`,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          this.listKelas = resp.data;
        });
      this.$q.loading.hide();
    },
    async getSiswaList() {
      this.$q.loading.show({ message: "Memuat Data siswa..." });
      await this.$http
        .get(
          "/akademik/rombel/getsiswa/" +
            this.selJenjang +
            "/" +
            this.selTingkat.value,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          resp.data.forEach((el) => {
            el.check = false;
          });
          this.listKiri = resp.data;
        });
      this.$q.loading.hide();
      this.print();
    },
    async getSiswaKelasList() {
      await this.$http
        .get(
          "/akademik/rombel/getsiswa/" +
            this.selJenjang +
            "/" +
            this.selTingkat.value +
            "/" +
            this.selKelas.value,
          this.$hlp.getHeader()
        )
        .then((resp) => {
          resp.data.forEach((el) => {
            el.check = false;
          });
          this.listKanan = resp.data;
        });
      this.print();
    },
  },
};
</script>

<style lang="scss" scoped></style>
